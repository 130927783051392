var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "100px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.realName,
                      callback: function ($$v) {
                        _vm.realName = $$v
                      },
                      expression: "realName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "职位名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.posName,
                      callback: function ($$v) {
                        _vm.posName = $$v
                      },
                      expression: "posName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属组织" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请日期" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.applicationDate,
                      callback: function ($$v) {
                        _vm.applicationDate = $$v
                      },
                      expression: "applicationDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.beginTime,
                      callback: function ($$v) {
                        _vm.beginTime = $$v
                      },
                      expression: "beginTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "总申请时长（天）" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.overtimeDuration,
                      callback: function ($$v) {
                        _vm.overtimeDuration = $$v
                      },
                      expression: "overtimeDuration",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批状态" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.bpmStatusName,
                      callback: function ($$v) {
                        _vm.bpmStatusName = $$v
                      },
                      expression: "bpmStatusName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请原因" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 3, readonly: "" },
                    model: {
                      value: _vm.overtimeReason,
                      callback: function ($$v) {
                        _vm.overtimeReason = $$v
                      },
                      expression: "overtimeReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "详情" } },
                [
                  _c(
                    "vxe-table",
                    { attrs: { data: _vm.timeInfoListJson } },
                    [
                      _c("vxe-table-column", {
                        attrs: { field: "timeStr", title: "日期" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "timeTypeName", title: "类型" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }