<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="人员姓名">
          <el-input v-model="realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="职位名称">
          <el-input v-model="posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属组织">
          <el-input v-model="orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请日期">
          <el-input v-model="applicationDate" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开始时间">
          <el-input v-model="beginTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="结束时间">
          <el-input v-model="endTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="总申请时长（天）">
          <el-input v-model="overtimeDuration" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="审批状态">
          <el-input v-model="bpmStatusName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="申请原因">
          <el-input v-model="overtimeReason" type="textarea" :rows="3" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="详情">
          <vxe-table :data="timeInfoListJson">
            <vxe-table-column field="timeStr" title="日期"></vxe-table-column>
            <vxe-table-column field="timeTypeName" title="类型"></vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
const timeTypeObj = {
  1: '全天',
  2: '上午',
  3: '下午',
};
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          realName: '', // 人员姓名
          posName: '', // 职位名称
          orgName: '', // 所属组织
          applicationDate: '', // 申请日期
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          overtimeDuration: '', // 总申请时长
          bpmStatusName: '', // 审批状态
          timeInfoListJson: [], // 详情
          overtimeReason: '', // 申请原因
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.realName = val.realName;
          this.posName = val.posName;
          this.orgName = val.orgName;
          this.applicationDate = val.applicationDate;
          this.beginTime = val.beginTime;
          this.endTime = val.endTime;
          this.overtimeDuration = val.overtimeDuration;
          this.bpmStatusName = val.bpmStatusName;
          this.timeInfoListJson = val.timeInfoListJson ? JSON.parse(val.timeInfoListJson).map((item) => ({ ...item, timeTypeName: timeTypeObj[item.timeType] })) : [];
          this.overtimeReason = val.overtimeReason;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      realName: '', // 人员姓名
      posName: '', // 职位名称
      orgName: '', // 所属组织
      applicationDate: '', // 申请日期
      beginTime: '', // 开始时间
      endTime: '', // 结束时间
      overtimeDuration: '', // 总申请时长
      bpmStatusName: '', // 审批状态
      timeInfoListJson: [], // 详情
      overtimeReason: '', // 申请原因
    };
  },
};
</script>
